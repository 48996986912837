<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          page = 1;
          searchData;
        "
      >
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Coupon_Name')">
                <el-select
                  v-model.trim="formInline.couponRuleId"
                  filterable
                  size="15"
                  placeholder="请选择优惠券"
                  clearable
                >
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    :label="value.couponName"
                    :value="value.couponRuleId"
                    :key="value.couponRuleId"
                    v-for="value in couponList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select
              v-model.trim="formInline.operationId"
              filterable
              size="15"
              placeholder="请选择"
              clearable
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleListName"
              ></el-option>
            </el-select>
          </el-form-item> -->

              <el-form-item :label="$t('searchModule.Coupon_type')">
                <el-select v-model.trim="formInline.couponType">
                  <el-option label="全部" value />
                  <el-option label="现金券" :value="0" />
                  <el-option label="折扣券" :value="1" />
                  <el-option label="全免券" :value="2" />
                  <!-- <el-option label="小时券" :value="3" /> -->
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Usage_time')">
                <!-- <el-date-picker
              v-model="date1"
              type="datetime"
              placeholder="选择日期"
              @change="setStartTime"
            ></el-date-picker>至
            <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="setEndTime"></el-date-picker> -->
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :clearable="false"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <!-- <el-button
              type="primary"
              @click="
                page = 1;
                exportFile();
              "
            >{{ $t('button.export') }}</el-button>-->
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <!-- <el-table-column label="操作" width="70">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)">详情</el-button>
            </template>
          </el-table-column>-->
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
import timeRangePick from "@/components/timePicker";
export default {
  name: "",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    let startTime = new Date(date1.getTime() - 3600 * 1000 * 24 * 30);
    // console.log("--startTime", startTime);
    let date = new Date();
    date = new Date(date.getTime() - 3600 * 1000 * 24 * 1);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      couponList: [],
      roleListName: [],
      exportData: {},
      date1: startTime,
      date2: endTime,
      initStartTime: startTime,
      initEndTime: endTime,
      modelvalue: "",
      plateNumber: "",
      tableCols: [
        {
          prop: "couponName",
          label: "优惠券名称",
          width: "",
        },
        // {
        //   prop: "operationName",
        //   label: this.$t("list.Merchant"),
        //   width: "",
        // },
        {
          prop: "couponType",
          label: "优惠券类型",
          width: "",
          formatter: (row) => {
            if (row.couponType == 0) {
              return "现金券";
            } else if (row.couponType == 1) {
              return "折扣券";
            } else if (row.couponType == 2) {
              return "全免券";
            } else if (row.couponType == 3) {
              return "小时券";
            }
          },
        },
        {
          prop: "receiveAmount",
          label: "领取量",
          width: "",
          // formatter: row => {
          //   return this.payTypeList.filter(v => v.code === row.parkType)[0]
          //     .desc;
          // }
        },
        {
          prop: "usedAmount",
          label: "使用量",
          width: "",
          // formatter: (row) => {
          //   if (row.type == 0) {
          //     return "活动领取";
          //   } else if (row.type == 1) {
          //     return "手动派发";
          //   }
          // },
        },
        {
          prop: "totalMoney",
          label: "核销总额",
          width: "120",
          formatter: (row, column) => {
            if (row.totalMoney) {
              return Number(row.totalMoney / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
      ],
      valueDate: "",
      member: "",
      formInline: {
        couponRuleId: "",
        // operationId: "",
        couponType: "",
        startTime: "",
        endTime: "",
        name: `优惠券核销统计`,
      },
      tableData: [],
      pageSize: 15,
      page: 1,
      loading: false,
      total: 0,
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    dataTimeRest(type) {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month;
      if (type) {
        month = nowDate.getMonth() < 10 ? "0" + nowDate.getMonth() : nowDate.getMonth();
      } else {
        month =
          nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      }

      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleListName = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 优惠券
    getConList() {
      this.$axios
        .get("/acb/2.0/coupon/list", {
          data: {
            page: 1,
            pageSize: 100,
            // status: 0,
          },
        })
        .then((res) => {
          // this.loading = false;
          if (res.state == 0) {
            this.couponList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        let diff = this.date2.getTime() - this.date1.getTime();
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("结束时间不能小于开始时间");
          return false;
        } else if (diff > 3600 * 1000 * 24 * 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    indexMethod(index) {
      return index + 1;
    },

    // 首页列表查询
    searchData() {
      // console.log("this=优惠券核销明细", this.formInline);
      // console.log(
      //   "时间",
      //   this.valueDate == null || this.valueDate == ""
      //     ? ""
      //     : dateFormat(this.valueDate[0], "yyyy-MM-dd")
      // );
      // let flag = this.showLog();
      // if (!flag) {
      //   return;
      // }
      // couponid: "",
      // operationId: "",
      // couponType: "",
      // mobile: "",
      // parkId: "",
      // parkName: "",
      // carId: "",
      // plateNumber: "",
      // startTime: startTime,
      // endTime: endTime,

      this.$axios
        .get("/acb/2.0/couponStatistics/total", {
          data: {
            page: this.page,
            size: this.pageSize,

            couponRuleId: this.formInline.couponRuleId,
            // operationId: this.formInline.operationId,
            couponType: this.formInline.couponType,
            startTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            // this.total = res.value.total * 1;
            this.total = res.value ? res.value.total * 1 : 0;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      // console.log(
      //   "时间",
      //   this.valueDate == null || this.valueDate == ""
      //     ? ""
      //     : dateFormat(this.valueDate[0], "yyyy-MM-dd")
      // );
      let opt = {};
      opt = {
        pageNum: this.page,
        pageSize: this.pageSize,
        type: this.formInline.type,
        correctStartTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[0], "yyyy-MM-dd"),
        correctEndTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[1], "yyyy-MM-dd"),
        parkId: this.formInline.parkId,

        carId: this.formInline.carId,
      };

      // /acb/2.0/specialplate/export
      //   exportExcelNew("bacop/2.0/plateCorrectRecord/exportRecord", opt, "post");
    },
    resetForm() {
      this.plateNumber = "";
      this.modelvalue = "";
      this.member = "";
      this.formInline = {
        couponRuleId: "",
        // operationId: "",
        couponType: "",
        name: `优惠券核销统计`,
      };
      // this.date1 = this.initStartTime;
      // this.date2 = this.initEndTime;
      this.$refs.timeRangePicker.resetTime();
      this.exportData = {
        url: "/acb/2.0/couponStatistics/total/export",
        methods: "get",
        data: this.formInline,
      };
    },
  },
  components: {
    exportFile,
    timeRangePick,
  },
  created() {
    this.getRoleList();
    this.getConList();
    this.defalutDate = [
      this.dataTimeRest(true) + " 00:00:00",
      this.dataTimeRest(false) + " 23:59:59",
    ];
  },
  mounted() {
    this.searchData();
    // console.log("当车按钮权限为==>", this.$route.meta.authority);
    this.exportData = {
      url: "/acb/2.0/couponStatistics/total/export",
      methods: "get",
      data: this.formInline,
      // data: data,
    };
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
